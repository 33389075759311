import React from "react";
import { Link } from "gatsby";
import { render } from "react-dom";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import caro1 from "../images/image2.jpg";
import main1 from "../images/image3.jpg";
import main2 from "../images/image4.jpg";
import main3 from "../images/image5.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CookieConsent from "react-cookie-consent";

const url =
  "https://vezeroptika.us4.list-manage.com/subscribe/post?u=48b7bcc9f4f98ccbd41b98dca&amp;id=b8f211dd99";
import { Parallax } from "react-scroll-parallax";
const CustomForm = ({ status, message, onValidated }) => {
  let email, name;
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      NAME: name.value
    });

  return (
    <div
      style={{
        background: "transparent",
        borderRadius: 2,
        padding: 10,
        display: "inline-block"
      }}
    >
      {status === "sending" && <div style={{ color: "blue" }}>Küldés...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input ref={node => (name = node)} type="text" placeholder="Az Ön neve" />
      <br />
      <input
        ref={node => (email = node)}
        type="email"
        placeholder="Az Ön e-mail címe"
      />
      <br />
      <button style={{}} onClick={submit}>
        Feliratkozás
      </button>
    </div>
  );
};

var settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 600,
  autoplaySpeed: 6000,
  cssEase: "linear",
  pauseOnHover: false
};
const IndexPage = () => (
  <Layout>
    <CookieConsent
      location="bottom"
      buttonText="Elfogadom"
      cookieName="myAwesomeCookieName2"
      style={{ background: "#FFFFFF" }}
      buttonStyle={{ color: "#F12F7B", fontSize: "13px" }}
      expires={150}
    >
      <p class="caro2text self-center">
        Ez a weboldal sütiket használ a felhasználói élmény maximalixálásának
        érdekében.
      </p>
    </CookieConsent>
    <SEO title="Home" description="Egyénre szabott optika Zugló szívében" />

    <Slider {...settings}>
      <div className="carosize">
        <h1 class="text-center caro1text buttonmain ">
          Köszöntjük honlapunkon!
        </h1>
      </div>
      <div>
        <div className="carosize2">
        <h1 class="text-center caro1text buttonmain ">
            Hírlevél tagjainknak ingyenes Szemüveg beállítás és Ultrahangos
            tisztítás
          </h1>
        </div>
      </div>
      <div>
        <div className="carosize3">

        </div>
      </div>
    </Slider>
    <div className="sitewrap xl:max-w-2xl   m-auto">
      <div className="  flex  customflex flex-wrap   ">
        <div className="F12F7B  w-full md:w-1/2  custom-h  p-4  text-left flex-col flex jus justify-around mt-10 md:mt-0 ">
          <div>
            <h3 className="maintext2 mt-2  ">
              Boltunkban a tökéletességre törekszünk.
            </h3>
          </div>
          <div>
            <h3 className="maintext2 mt-2 ">
              A professzionális és egyénre szabott szemüvegkészítésben hiszünk.
            </h3>
          </div>
        </div>
        <div className=" w-full md:w-1/2  custom-h ">
          <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
            <img src={main1} alt="Logo" className=" mainimg1 " />
          </Parallax>
        </div>
      </div>
      <div className="  flex  customflex flex-wrap ">
        <div className=" w-full md:w-1/2  custom-h ">
          <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
            <img src={main2} alt="Logo" className=" mainimg1 " />
          </Parallax>
        </div>
        <div className="F12F7B  w-full md:w-1/2  custom-h  p-4  text-left flex-col flex jus justify-around ">
          <div>
            <h3 className="maintext2 mt-2">
              Minden szemüveg a szemvizsgálattól kezdve a fejreigazításig
              okleveles munkatársaink által készül.
            </h3>
          </div>
          <div>
            <h3 className="maintext2 mt-2">
              A tökéletes eredmény csak így érhető el.
            </h3>
          </div>
        </div>
      </div>

      <div className="flex  justify-between flex-wrap mt-20">
        <div className=" w-full md:w-1/4  custom-h2  choose text-left flex-col flex jus justify-around ">
          <div>
            <h4 className="maintext1 mt-2 m-2">Elérhetőségünk és címünk </h4>
          </div>
          <div>
            <div className="md:mr-4 md:ml-4 ml-20 mr-20  contactsvg">
              <Link to="kapcsolat/#elerhetoseg">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="map-marked-alt"
                  className="w-4/5 mr-auto ml-auto contactsvg"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>

        <div className=" w-full md:w-1/4  mt-10 md:mt-0 custom-h2  choose text-left flex-col flex jus justify-around ">
          <div>
            <h4 className="maintext1 mt-2 m-2">Termékeink </h4>
          </div>
          <div>
            <div className="md:mr-4 md:ml-4 ml-20 mr-20 contactsvg">
              <Link to="termekeink">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="glasses"
                  className="w-4/5 mr-auto ml-auto contactsvg"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M574.1 280.37L528.75 98.66c-5.91-23.7-21.59-44.05-43-55.81-21.44-11.73-46.97-14.11-70.19-6.33l-15.25 5.08c-8.39 2.79-12.92 11.86-10.12 20.24l5.06 15.18c2.79 8.38 11.85 12.91 20.23 10.12l13.18-4.39c10.87-3.62 23-3.57 33.16 1.73 10.29 5.37 17.57 14.56 20.37 25.82l38.46 153.82c-22.19-6.81-49.79-12.46-81.2-12.46-34.77 0-73.98 7.02-114.85 26.74h-73.18c-40.87-19.74-80.08-26.75-114.86-26.75-31.42 0-59.02 5.65-81.21 12.46l38.46-153.83c2.79-11.25 10.09-20.45 20.38-25.81 10.16-5.3 22.28-5.35 33.15-1.73l13.17 4.39c8.38 2.79 17.44-1.74 20.23-10.12l5.06-15.18c2.8-8.38-1.73-17.45-10.12-20.24l-15.25-5.08c-23.22-7.78-48.75-5.41-70.19 6.33-21.41 11.77-37.09 32.11-43 55.8L1.9 280.37A64.218 64.218 0 0 0 0 295.86v70.25C0 429.01 51.58 480 115.2 480h37.12c60.28 0 110.37-45.94 114.88-105.37l2.93-38.63h35.75l2.93 38.63C313.31 434.06 363.4 480 423.68 480h37.12c63.62 0 115.2-50.99 115.2-113.88v-70.25c0-5.23-.64-10.43-1.9-15.5zm-370.72 89.42c-1.97 25.91-24.4 46.21-51.06 46.21H115.2C86.97 416 64 393.62 64 366.11v-37.54c18.12-6.49 43.42-12.92 72.58-12.92 23.86 0 47.26 4.33 69.93 12.92l-3.13 41.22zM512 366.12c0 27.51-22.97 49.88-51.2 49.88h-37.12c-26.67 0-49.1-20.3-51.06-46.21l-3.13-41.22c22.67-8.59 46.08-12.92 69.95-12.92 29.12 0 54.43 6.44 72.55 12.93v37.54z"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>

        <div className=" w-full md:w-1/4  md:mt-0 mt-20 custom-h2  choose text-left flex-col flex jus justify-around ">
          <div>
            <h4 className="maintext1 mt-2 m-2">Szolgáltatásaink </h4>
          </div>
          <div>
            <div className="md:mr-4 md:ml-4 ml-20 mr-20 contactsvg">
              <Link to="szolgaltatasaink">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="microscope"
                  className="w-4/5 mr-auto ml-auto contactsvg"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M160 320h12v16c0 8.84 7.16 16 16 16h40c8.84 0 16-7.16 16-16v-16h12c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32V16c0-8.84-7.16-16-16-16h-64c-8.84 0-16 7.16-16 16v16c-17.67 0-32 14.33-32 32v224c0 17.67 14.33 32 32 32zm304 128h-1.29C493.24 413.99 512 369.2 512 320c0-105.88-86.12-192-192-192v64c70.58 0 128 57.42 128 128s-57.42 128-128 128H48c-26.51 0-48 21.49-48 48 0 8.84 7.16 16 16 16h480c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48zm-360-32h208c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H104c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8z"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className=" w-full   custom-h3 mt-20  ">
        <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure">
          <img src={main3} alt="Logo" className=" mainimg1 " />
        </Parallax>
      </div>

      <div className="flex  justify-around flex-wrap mt-16">
        <div className=" w-full md:w-1/4 md:mt-0  custom-h2  choose text-left flex-col flex jus justify-around ">
          <div>
            <h4 className="maintext1 mt-2 m-2">Vevőszolgálat </h4>
          </div>
          <div>
            <div className="md:mr-4 md:ml-4 ml-20 mr-20 contactsvg">
              <Link to="kapcsolat/#vevoszolgalat">
                <svg
                  className="w-4/5 mr-auto ml-auto contactsvg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="globe"
                  class="svg-inline--fa fa-globe fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 496 512"
                >
                  <path
                    fill="currentColor"
                    d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className=" w-full md:w-1/4 mt-10  md:mt-0 custom-h2  choose text-left flex-col flex jus justify-around ">
          <div>
            <h4 className="maintext1 mt-2 m-2">
              Kövessen minket a Facebookon!
            </h4>
          </div>
          <div>
            <div className="md:mr-4 md:ml-4 ml-20 mr-20 contactsvg">
              <a
                key={13}
                className="self-center   "
                href={"https://www.facebook.com/vezeroptika"}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="facebook"
                  className="w-4/5 mr-auto ml-auto contactsvg"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="  justify-around   mt-16">
        <h1 class="hirlevel text-center">
          Ne mulassza el újdonságaink és akcióink
        </h1>
        <h3 class="hirlevel text-center mt-2">Iratkozzon fel hirlevelünkre</h3>
      </div>
      <div className="  justify-around  mt-2 pb-6 text-center">
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
